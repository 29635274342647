.genuine__container {
  text-align: center;
  font-size: calc(10px + 2vmin);
  height: 90vh;
  overflow: hidden;
}
.metaltech-logo__container {
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.metaltech-logo {
  max-width: 250px;
  height: auto;
  object-fit: contain;
}
.check__container {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.genuine-title__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25%;
  font-weight: 700;
  color:#202020;
  text-transform: uppercase;
  word-spacing: 1px;
  letter-spacing:2px;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: green;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: green;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px #fff;
  }
}
